var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-bodyz"
  }, [_c('div', {}, [_c('ul', {
    staticClass: "nav nav-tabs mt-4"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.amendment_path == 1 ? 'active' : '',
    attrs: {
      "to": {
        name: 'pindaan.form-pindaan-harta-tidak-alih',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          property_id: this.property_id,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.ownership2")))])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.amendment_path == 2 ? 'active' : '',
    attrs: {
      "to": {
        name: 'pindaan.receiver-harta-tidak-alih',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          property_id: this.property_id,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment.add-receiver")))])], 1), _c('li', {
    staticClass: "nav-item"
  }, [_c('router-link', {
    staticClass: "nav-link",
    class: _vm.amendment_path == 3 ? 'active' : '',
    attrs: {
      "to": {
        name: 'pindaan.form-pindaan-dokumen-tidak-alih',
        params: {
          wasiat_id: this.wasiat_id
        },
        query: {
          property_id: this.property_id,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("document")))])], 1)]), _c('div', {
    staticClass: "col-12"
  }, [_c('router-view')], 1)])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }